import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const style = (theme) => ({
  costTableRow: {
    borderBottom: `2px solid ${fade(theme.palette.primary.main, 0.2)}`,
  },
  costTableCell: {
    verticalAlign: 'top',
    padding: theme.spacing(2, 0),
  },
  costTableValue: {
    fontWeight: '700',
    whiteSpace: 'nowrap',
  },
  costTableValueGreen: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(0.5),
  },
  titleIconGas: {
    color: theme.palette.secondary.main,
  },
  titleIconElectricity: {
    color: theme.palette.success.main,
  },
  budgetCopy: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tilDialog: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  btnMargin: {
    marginTop: theme.spacing(2),
  },
});

export default makeStyles(style);
