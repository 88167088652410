import { makeStyles } from '@material-ui/core/styles';
import { colorsCommon } from '@utilitywarehouse/colour-system';

const style = (theme) => ({
  container: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.text.primary,
    color: colorsCommon.brandWhite,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  textContainer: {
    alignContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 24,
  },
  highlight: {
    color: colorsCommon.brandPink,
  },
});

export default makeStyles(style);
