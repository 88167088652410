import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import VisibilitySensor from 'react-visibility-sensor';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { TARIFF } from 'app/redux/modules/Energy/Quote/constants';
import Line from 'modules/Shared/LineSeparator';
import Shadow from 'modules/Shared/ShadowSeparator';
import styles from './styles';

function dataTestId(fieldLabel, suffix) {
  return `${fieldLabel.toString().replace(/\s/g, '-')}${suffix}`;
}

function isTariffType(tariff, expectedTariff) {
  return (tariff || '').toUpperCase() == expectedTariff;
}

class TilDialog extends React.Component {
  static defaultProps = {
    name: '',
    type: '',
    paymentMethod: '',
    unitRate: '',
    standingCharge: '',
    endDate: '',
    exitFee: '',
    discounts: '',
    additionalReductions: '',
    closeFn: () => {},
    supplier: 'The Utility Warehouse (Electricity Plus Supply Ltd)',
  };

  dataListComponents() {
    const getUnitRateFields = () => {
      const { unitRate, nightUnitRate } = this.props;
      if (nightUnitRate) {
        return {
          'Day Time Unit Rate': unitRate,
          'Night Time Unit Rate': nightUnitRate,
        };
      } else {
        return {
          'Unit Rate': unitRate,
        };
      }
    };

    const fields = {
      Supplier: this.props.supplier,
      'Tariff Name': this.props.name,
      'Tariff Type': this.props.type,
      'Payment Method': this.props.paymentMethod,
      ...getUnitRateFields(),
      'Standing Charge': this.props.standingCharge,
      'Tariff ends on': this.props.endDate,
      'Price guaranteed until': this.props.endDate,
      'Exit Fees': this.props.exitFee,
      Discounts: this.props.discounts,
      'Additional reductions': this.props.additionalReductions,
    };

    const components = [];
    Object.keys(fields).forEach((key) => {
      components.push(
        <div key={key}>
          <ul style={styles.tariffLabel.ul}>
            <li
              data-test-id={dataTestId(key, 'Description')}
              style={styles.tariffLabel.liKey}
            >
              <Typography variant="body1">{key}</Typography>
            </li>
            <li
              data-test-id={dataTestId(key, 'Value')}
              style={styles.tariffLabel.liFields}
            >
              <Typography variant="body1">{fields[key]}</Typography>
            </li>
          </ul>
          <Line />
        </div>
      );
    });

    return components;
  }

  render() {
    return (
      <Dialog open onClose={this.props.closeFn}>
        <DialogTitle>
          Tariff Information Label{this.state.componentHeight}
        </DialogTitle>
        <DialogContent style={styles.container}>
          {this.dataListComponents()}
          <Typography variant="body1">
            All prices and costs shown above are inclusive of VAT at 5%. This is
            different to the prices shown on your Bills and Statements which are
            shown excluding VAT which is then added separately. The prices shown
            above reflect our current prices available.{' '}
            {!isTariffType(this.props.type, TARIFF.FIXED)
              ? 'These prices may increase in the future and if they do, this TIL will be updated accordingly.'
              : ''}
          </Typography>
          <div style={styles.tariffLabel.faqs}>
            <Typography variant="body1" style={styles.tariffLabel.faqs.title}>
              Frequently asked questions
            </Typography>
            <div style={styles.tariffLabel.faqs.qa}>
              <i style={styles.tariffLabel.faqs.qa.question}>
                <Typography variant="body1">
                  Q. What is a kWh (Kilowatt-hour)?
                </Typography>
              </i>
              <br />
              <Typography variant="body1">
                A. The energy you use is measured in kWh's.
              </Typography>
            </div>
          </div>
          <Shadow />
          <VisibilitySensor
            onChange={((visible) =>
              this.setState({ hideScrollImg: !visible })).bind(this)}
          />
        </DialogContent>
        <DialogActions>
          {this.state.hideScrollImg && (
            <img
              style={styles.moreIcon}
              alt="test"
              src="images/icons/small/more.svg"
            />
          )}
          <div style={styles.closeContainer}>
            <Button
              variant="contained"
              label="Close"
              onClick={this.props.closeFn}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}
TilDialog.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  paymentMethod: PropTypes.string,
  unitRate: PropTypes.string,
  standingCharge: PropTypes.string,
  endDate: PropTypes.string,
  exitFee: PropTypes.string,
  discounts: PropTypes.string,
  additionalReductions: PropTypes.string,
  closeFn: PropTypes.func,
  annualConsumption: PropTypes.string,
  annualCost: PropTypes.string,
  supplier: PropTypes.string,
  nightUnitRate: PropTypes.string,
};

export default Radium(TilDialog);
