import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Link,
} from '@material-ui/core';
import { TickSmallIcon } from '@utilitywarehouse/react-icons';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';

import { useStyles } from './styles';
import { EV_TARIFF_URL } from 'app/config/externalLinks';

const ListItem = ({ children }) => {
  const classes = useStyles();

  return (
    <li className={classes.listItem}>
      <span className={classes.tick}>
        <TickSmallIcon />
      </span>
      <Typography>{children}</Typography>
    </li>
  );
};

const EVInterestForm = ({ sectionRef, onCheck, checked }) => {
  const classes = useStyles();
  const tracking = useTracking();

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleCheck = (value) => {
    onCheck(value);
    if (value) {
      tracking.track(EVENTS.ENERGY_EV_TARIFF_MARKETING_INTEREST_EXPRESSED);
    }
  };
  return (
    <section>
      <div className={classes.container}>
        <div className={classes.evDescription}>
          <Typography variant="h2" className={classes.title}>
            Introducing our variable{' '}
            <span className={classes.green}>Electric Vehicle Tariff</span>
          </Typography>
          <Typography>
            Save money on your home charging with our low-priced overnight
            electricity, available exclusively to UW customers
          </Typography>
          <ul className={classes.list}>
            <ListItem>
              Perfect if you can consistently charge between midnight and 5am.{' '}
              <Link
                className={classes.link}
                href={EV_TARIFF_URL}
                rel="noopener noreferrer"
                target="_blank"
                onClick={() => {
                  tracking.track(EVENTS.ENERGY_EV_TARIFF_FULL_DETAILS_CLICKED);
                }}
              >
                See full EV Tariff details here.
              </Link>
            </ListItem>
            <ListItem>
              The more UW services you take, the lower your overnight rate
            </ListItem>
            <ListItem>Works with all vehicles and chargers</ListItem>
            <ListItem>No exit fees</ListItem>
          </ul>
        </div>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            src="images/evTariff/car_1_circle_forWhite.png"
            alt="ev tariff"
          />
        </div>
      </div>
      <div className={classes.formContainer} ref={sectionRef}>
        <div className={classes.formTitle}>
          <img src="images/evTariff/email.svg" alt="email icon" />
          <Typography variant="h4" className={classes.formTitleHeading}>
            Email me more information about EV Tariffs
          </Typography>
        </div>
        <Typography className={classes.formDescription}>
          Once we've taken over your electricity supply and confirmed your smart
          meter is working, we'll send you more details about our EV tariff.
          Switching to the EV Tariff takes just one click - quick and simple.
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControlLabel
            classes={{ root: classes.formLabel }}
            control={<Checkbox />}
            name="emailInterest"
            label="That sounds great! Email me with more information."
            checked={checked}
            onChange={(_, value) => handleCheck(value)}
          />
        </form>
      </div>
    </section>
  );
};

ListItem.propTypes = {
  children: PropTypes.node,
};

EVInterestForm.propTypes = {
  checked: PropTypes.bool.isRequired,
  sectionRef: PropTypes.object,
  onCheck: PropTypes.func.isRequired,
};

export { EVInterestForm };
