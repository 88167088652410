import {
  getSpacing,
  getServicePaletteColor,
  getBreakpoints,
} from 'app/lib/theme';

export default (theme) => {
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);
  const electricity = getServicePaletteColor('electricity')(theme);
  const gas = getServicePaletteColor('gas')(theme);

  return {
    contentCard: {
      padding: spacing(2),
    },
    contentPaddingCart: {
      padding: spacing(2),
      [breakpoints.up('sm')]: {
        padding: spacing(2, 4, 2, 2),
      },
    },
    big: {
      height: 192,
    },
    iconContainer: {
      '& > *:last-child': {
        marginLeft: spacing(2),
      },
    },
    icon: {
      height: 48,
      marginBottom: spacing(3),
      width: 48,
    },
    gas: { color: gas },
    electricity: { color: electricity },
    choiceContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 69,
      marginBottom: spacing(3),
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    title: {
      textAlign: 'left',
      padding: spacing(5, 0, 4, 0),
    },
    root: {
      alignItems: 'stretch',
      display: 'flex',
      flexGrow: 1,
      width: 'auto',
      [breakpoints.up('sm')]: {
        width: 216,
      },
    },
    topLeft: {
      alignItems: 'start',
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'start',
      textAlign: 'left',
    },
    margin: {
      marginBottom: spacing(1.5),
    },
    prepaid: {
      marginBottom: spacing(3),
    },
    center: {
      margin: spacing(2, 0),
      [breakpoints.up('sm')]: {
        margin: spacing(0, 2),
      },
    },
    right: {
      marginBottom: spacing(2),
      marginRight: spacing(0),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(0),
        marginRight: spacing(2),
      },
    },
    buttonBase: {
      width: '100%',
    },
  };
};
