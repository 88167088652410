import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Typography from '@material-ui/core/Typography';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { createTheme } from '@utilitywarehouse/partner-ui-mui-theme';
import { LockMediumIcon } from '@utilitywarehouse/react-icons';
import { TARIFF_LABELS } from 'redux/modules/Energy/Quote/constants';
import { FIXED_TARIFF_MINIMAL_SERVICES_AMOUNT } from 'redux/modules/Energy/constants';
import { BundlesPage } from 'redux/modules/Routes/constants';
import { endDateCopyHandler } from 'redux/modules/Energy/utils';
import TariffSwitch from './TariffSwitch';
import Bonuses from './Bonuses';
import styles from './styles';
import { getMonthlyProjection } from './TariffSwitch';

const darkTheme = createMuiTheme(createTheme('dark'));

// if only one energy service selected and it's a prepayment
const isOnlyPrepaymentTariff = ({ electricity, gas }) => {
  if (electricity && electricity.tariff.name !== TARIFF_LABELS.PREPAYMENT) {
    return false;
  }

  if (gas && gas.tariff.name !== TARIFF_LABELS.PREPAYMENT) {
    return false;
  }

  return true;
};

const hasPrepayment = ({ electricity, gas }) =>
  electricity?.tariff.name === TARIFF_LABELS.PREPAYMENT ||
  gas?.tariff.name === TARIFF_LABELS.PREPAYMENT;

const TopSwitch = ({
  handleToggleTariff,
  hasDaffodil,
  current,
  tariffType,
  isTenant,
  selectedServices,
  classes,
  offeredQuote,
}) => {
  const renderFixedTariffInfo = () => {
    if (isEmpty(offeredQuote)) {
      return null;
    }

    const label = offeredQuote.tariff.replace(/\d+|^\s+|\s+$/g, '');

    return (
      <div
        className={classes.messageContainer}
        data-test-id="FixedTariffInfoBox"
      >
        <LockMediumIcon className={classes.lock} />
        <Typography>
          <Link to={BundlesPage} className={classes.colorBoldFont}>
            Add another service
          </Link>{' '}
          to unlock our {label} tariff for{' '}
          <strong>{`£${getMonthlyProjection(offeredQuote)}`}</strong> a month
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div className={classes.container} data-test-id="EnergyTariffSwitch">
        <div className={classes.left}>
          <MuiThemeProvider theme={darkTheme}>
            <TariffSwitch
              hasDaffodil={hasDaffodil}
              currentQuote={current}
              handleToggleTariff={handleToggleTariff}
              isPrepaymentTariff={isOnlyPrepaymentTariff(current)}
            />
            {selectedServices.length <= FIXED_TARIFF_MINIMAL_SERVICES_AMOUNT &&
              !isOnlyPrepaymentTariff(current) &&
              renderFixedTariffInfo()}
          </MuiThemeProvider>
        </div>
        <div className={classes.right}>
          <div className={classes.bonuses}>
            <Bonuses
              tariffType={tariffType}
              isTenant={isTenant}
              date={endDateCopyHandler(current, true)}
              selectedServices={selectedServices}
            />
          </div>
        </div>
      </div>
      <Typography classes={{ root: classes.copy }}>
        Based on the information you have given us, we've calculated a personal
        cost projection for each fuel below.
      </Typography>
      {!hasPrepayment(current) && (
        <Typography>
          These costs are for the selected tariff paying by monthly Direct
          Debit. If your consumption or our rates change, so would your
          projection.
        </Typography>
      )}
    </>
  );
};

TopSwitch.propTypes = {
  handleToggleTariff: PropTypes.func.isRequired,
  hasDaffodil: PropTypes.bool,
  current: PropTypes.object,
  tariffType: PropTypes.string,
  isTenant: PropTypes.bool,
  selectedServices: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  offeredQuote: PropTypes.object,
};

export default withStyles(styles)(TopSwitch);
