import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';
import { ElectricityIcon, GasIcon } from '@utilitywarehouse/partner-ui-icons';

import {
  PRODUCT_ELECTRICITY_AND_GAS,
  PRODUCT_ELECTRICITY,
  PRODUCT_GAS,
} from 'redux/modules/Energy/ProductSelectionForm/constants';
import styles from './styles';

const EnergySelector = ({ selected, handleChange, classes }) => (
  <>
    <Typography className={classes.title} color="primary" variant="h3">
      Your Energy
    </Typography>
    <div className={classes.choiceContainer}>
      <CheckCard
        checkCardClasses={{
          root: classNames(classes.root, classes.big),
          buttonBase: classes.buttonBase,
        }}
        checked={selected === PRODUCT_ELECTRICITY_AND_GAS}
        name="energyType"
        onChange={handleChange}
        value={PRODUCT_ELECTRICITY_AND_GAS}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <div className={classes.iconContainer}>
            <GasIcon className={classNames(classes.icon, classes.gas)} />
            <ElectricityIcon
              className={classNames(classes.icon, classes.electricity)}
            />
          </div>
          <Typography color="primary" variant="h4">
            Gas and Electricity
          </Typography>
        </CardContent>
      </CheckCard>
      <CheckCard
        checkCardClasses={{
          root: classNames(classes.root, classes.center, classes.big),
          buttonBase: classes.buttonBase,
        }}
        checked={selected === PRODUCT_ELECTRICITY}
        name="energyType"
        onChange={handleChange}
        value={PRODUCT_ELECTRICITY}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <ElectricityIcon
            className={classNames(classes.icon, classes.electricity)}
          />
          <Typography color="primary" variant="h4">
            Electricity only
          </Typography>
        </CardContent>
      </CheckCard>
      <CheckCard
        checkCardClasses={{
          root: classNames(classes.root, classes.big),
          buttonBase: classes.buttonBase,
        }}
        checked={selected === PRODUCT_GAS}
        name="energyType"
        onChange={handleChange}
        value={PRODUCT_GAS}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <GasIcon className={classNames(classes.icon, classes.gas)} />
          <Typography color="primary" variant="h4">
            Gas only
          </Typography>
        </CardContent>
      </CheckCard>
    </div>
  </>
);

EnergySelector.propTypes = {
  selected: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnergySelector);
