import { makeStyles } from '@material-ui/core/styles';
import { colors, colorsCommon } from '@utilitywarehouse/colour-system';

const HIDE_IMAGE_BREAKPOINT = '1280px';

const style = ({ spacing, typography, breakpoints }) => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colorsCommon.brandWhite,
    borderRadius: spacing(1),
    border: `1px solid ${colors.grey175}`,
    marginTop: spacing(3),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3, 0, 3, 2),

    [`@media (max-width: ${HIDE_IMAGE_BREAKPOINT})`]: {
      padding: spacing(3, 2),
    },
  },
  green: {
    color: colors.green600,
  },
  title: {
    marginBottom: spacing(1),
    fontSize: 24,

    [breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '26px',
    },
  },
  link: {
    color: colors.cyan600,
    fontWeight: typography.fontWeightMedium,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'right',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    alignSelf: 'stretch',
    maxHeight: '100%',
    borderRadius: spacing(0, 1, 1, 0),
    overflow: 'hidden',

    [`@media (max-width: ${HIDE_IMAGE_BREAKPOINT})`]: {
      display: 'none',
    },
  },
  image: {
    maxHeight: '100%',
  },
  imagePlaceholder: {
    [`@media (max-width: ${HIDE_IMAGE_BREAKPOINT})`]: {
      display: 'none',
    },
  },
  description: {
    display: 'flex',
    gap: spacing(1),
    flexWrap: 'wrap',
    zIndex: 1,
  },
  alert: {
    marginTop: spacing(3),
    maxWidth: '100%',
  },
});

export const useStyles = makeStyles(style);
