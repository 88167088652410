import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@utilitywarehouse/colour-system';

const TICK_SIZE = 21;

const styles = ({ breakpoints, spacing }) => ({
  container: {
    display: 'flex',
    gap: spacing(3),
  },
  evDescription: {
    flex: 1,
    marginBottom: spacing(3),
  },
  title: {
    marginBottom: spacing(2),
    [breakpoints.down('sm')]: {
      fontSize: 22,
      textWrap: 'wrap',
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '40%',
    alignSelf: 'stretch',

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  image: {
    maxWidth: '100%',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginTop: spacing(3),
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',

    '& + &': {
      marginTop: spacing(2),
    },
  },
  tick: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: TICK_SIZE,
    height: TICK_SIZE,
    flexShrink: 0,
    marginRight: spacing(1),
    borderRadius: '50%',
    border: `2px solid ${colors.green600}`,
    color: colors.green600,
  },
  link: {
    color: colors.cyan600,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  green: {
    color: colors.green600,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
    padding: spacing(2),
    border: `1px solid ${colors.grey175}`,
    borderRadius: 12,
  },
  formTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(1),

    '& > *': {
      color: colors.purple800,
    },
  },
  formTitleHeading: {
    [breakpoints.down('sm')]: {
      fontSize: 20,
      textWrap: 'wrap',
    },
  },
  formLabel: {
    paddingLeft: spacing(2),
  },
});

const useStyles = makeStyles(styles);

export { useStyles };
