import React from 'react';
import { Typography, Paper } from '@material-ui/core';

import useStyles from './styles';

const Banner = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.container}>
      <div className={classes.textContainer}>
        <Typography variant="h4" className={classes.title}>
          Upgrade to a smart meter for free
        </Typography>
        <Typography variant="body1">
          You’ll see a link to book a free smart meter upgrade as soon as you’ve
          signed up.
        </Typography>
      </div>
    </Paper>
  );
};

export default Banner;
