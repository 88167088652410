import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ElectricityIcon, GasIcon } from '@utilitywarehouse/partner-ui-icons';

import {
  ELECTRICITY_CONSUMPTION,
  ELECTRICITY_DAY_CONSUMPTION,
  ELECTRICITY_NIGHT_CONSUMPTION,
  GAS_CONSUMPTION,
} from 'redux/modules/Energy/ProductSelectionForm/constants';
import useStyles from './styles';

const CustomUsageTable = ({
  showElectricity,
  showGas,
  showDayNightEnergy,
  electricityDayConsumption,
  electricityNightConsumption,
  electricityConsumption,
  gasConsumption,
  fieldErrors,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <CardContent classes={{ root: classes.container }}>
      {showElectricity && (
        <div className={classes.row}>
          <Typography
            classes={{ root: classes.title }}
            color="primary"
            variant="h4"
          >
            <ElectricityIcon
              className={classNames(classes.icon, classes.electricityIcon)}
            />
            Your Electricity Usage
          </Typography>
          {showDayNightEnergy ? (
            <div className={classes.inputContainer}>
              <TextField
                classes={{
                  root: classNames(classes.textField, classes.textFieldLeft),
                }}
                fullWidth
                margin="normal"
                onChange={(e) =>
                  handleChange(ELECTRICITY_DAY_CONSUMPTION, e.target.value)
                }
                placeholder="Annual Daily Electricity Usage"
                type="number"
                value={electricityDayConsumption}
                error={fieldErrors[ELECTRICITY_DAY_CONSUMPTION]}
                variant="filled"
                InputProps={{
                  inputProps: {
                    maxLength: 8,
                    min: 0,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        className={classes.inputIcon}
                        src="images/energySelection/Sun_blue.svg"
                        alt="sun icon"
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">kWh</InputAdornment>
                  ),
                }}
              />
              <TextField
                classes={{ root: classes.textField }}
                fullWidth
                margin="normal"
                onChange={(e) =>
                  handleChange(ELECTRICITY_NIGHT_CONSUMPTION, e.target.value)
                }
                placeholder="Annual Nightly Electricity Usage"
                type="number"
                value={electricityNightConsumption}
                error={fieldErrors[ELECTRICITY_NIGHT_CONSUMPTION]}
                variant="filled"
                InputProps={{
                  inputProps: {
                    maxLength: 8,
                    min: 0,
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        className={classes.inputIcon}
                        src="images/energySelection/Moon_blue.svg"
                        alt="moon icon"
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">kWh</InputAdornment>
                  ),
                }}
              />
            </div>
          ) : (
            <TextField
              classes={{
                root: classNames(classes.singleTextField, classes.textField),
              }}
              fullWidth
              margin="normal"
              onChange={(e) =>
                handleChange(ELECTRICITY_CONSUMPTION, e.target.value)
              }
              placeholder="Annual Electricity Usage"
              type="number"
              value={electricityConsumption}
              error={fieldErrors[ELECTRICITY_CONSUMPTION]}
              variant="filled"
              InputProps={{
                inputProps: {
                  maxLength: 8,
                  min: 0,
                },
                endAdornment: (
                  <InputAdornment position="end">kWh</InputAdornment>
                ),
              }}
            />
          )}
        </div>
      )}
      {showGas && (
        <div className={classes.row}>
          <Typography
            classes={{ root: classes.title }}
            color="primary"
            variant="h4"
          >
            <GasIcon className={classNames(classes.icon, classes.gasIcon)} />
            Your Gas Usage
          </Typography>
          <TextField
            classes={{
              root: classNames(classes.singleTextField, classes.textField),
            }}
            margin="normal"
            onChange={(e) => handleChange(GAS_CONSUMPTION, e.target.value)}
            placeholder="Annual Gas Usage"
            type="number"
            value={gasConsumption}
            error={fieldErrors[GAS_CONSUMPTION]}
            variant="filled"
            InputProps={{
              inputProps: {
                maxLength: 8,
                min: 0,
              },
              endAdornment: (
                <InputAdornment position="end" disableTypography>
                  kWh
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
    </CardContent>
  );
};

CustomUsageTable.propTypes = {
  showElectricity: PropTypes.bool.isRequired,
  showGas: PropTypes.bool.isRequired,
  showDayNightEnergy: PropTypes.bool.isRequired,
  electricityDayConsumption: PropTypes.string,
  electricityNightConsumption: PropTypes.string,
  electricityConsumption: PropTypes.string,
  gasConsumption: PropTypes.string,
  fieldErrors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
};

export default CustomUsageTable;
