import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';
import { ElectricityIcon, GasIcon } from '@utilitywarehouse/partner-ui-icons';

import { format } from 'redux/modules/Energy/ProductSelectionForm/helpers';
import {
  ENERGY_USE_EAC,
  ENERGY_USE_MEDIUM,
} from 'redux/modules/Energy/ProductSelectionForm/constants';
import useStyles from './styles';

const EstimatedAnnualConsumption = ({
  gas,
  electricity,
  showGas,
  showElectricity,
  showDayNightUsage,
  selected,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.title} color="primary" variant="h3">
        Your Energy Use
      </Typography>
      <div className={classes.container}>
        <Typography variant="body1" className={classes.subtitle}>
          Based on historical data, we consider your usage to be:
        </Typography>
        <div className={classes.wrapper}>
          <div className={classes.infoContainer}>
            {showElectricity && electricity && (
              <div className={classes.serviceWrapper}>
                <ElectricityIcon
                  className={classNames(classes.icon, classes.electricity)}
                />
                <Typography>
                  <span className={classes.bold}>
                    {format(electricity.total)}
                  </span>{' '}
                  kWh of electricity per year
                  {showDayNightUsage &&
                    ` (${format(electricity.day)} day / ${format(
                      electricity.night
                    )} night)`}
                </Typography>
              </div>
            )}
            {showGas && gas && (
              <div className={classes.serviceWrapper}>
                <GasIcon className={classNames(classes.icon, classes.gas)} />
                <Typography>
                  <span className={classes.bold}>{format(gas.total)}</span> kWh
                  of gas per year
                </Typography>
              </div>
            )}
            <Typography
              variant="body1"
              color="primary"
              className={classes.text}
            >
              These figures will be used to generate your quote.
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              className={classes.text}
            >
              Do you agree?
            </Typography>
          </div>
          <img
            className={classes.image}
            alt="energy use image"
            src="images/energySelection/Appliances.svg"
          />
        </div>
        <div className={classes.selectorContainer}>
          <CheckCard
            checkCardClasses={{
              root: classes.checkCardRoot,
              buttonBase: classes.checkCardBtnBase,
            }}
            checked={selected === ENERGY_USE_EAC}
            name="usageEstimation"
            onChange={(e) => {
              handleChange(e);
            }}
            value={ENERGY_USE_EAC}
            variant="radio"
          >
            <CardContent classes={{ root: classes.contentCard }}>
              <Typography color="primary" variant="h4">
                Yes
              </Typography>
            </CardContent>
          </CheckCard>
          <CheckCard
            checkCardClasses={{
              buttonBase: classes.checkCardBtnBase,
            }}
            checked={selected !== ENERGY_USE_EAC}
            name="usageEstimation"
            onChange={(e) => {
              if (selected === ENERGY_USE_EAC) handleChange(e);
            }}
            value={ENERGY_USE_MEDIUM}
            variant="radio"
          >
            <CardContent classes={{ root: classes.contentCard }}>
              <Typography color="primary" variant="h4">
                No / Don't know
              </Typography>
            </CardContent>
          </CheckCard>
        </div>
      </div>
    </>
  );
};

EstimatedAnnualConsumption.propTypes = {
  electricity: PropTypes.shape({
    day: PropTypes.number,
    night: PropTypes.number,
    total: PropTypes.number,
  }),
  gas: PropTypes.shape({
    total: PropTypes.number,
  }),
  selected: PropTypes.string,
  showGas: PropTypes.bool.isRequired,
  showElectricity: PropTypes.bool.isRequired,
  showDayNightUsage: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default EstimatedAnnualConsumption;
