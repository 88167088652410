import React from 'react';
import PropTypes from 'prop-types';
import { TARIFF_TYPES } from 'redux/modules/Energy/Quote/constants';

import {
  BonusesVariable,
  BonusesFixed,
  BonusesFixedTenant,
  BonusesPrepayment,
} from './bonuses';

const Bonuses = ({ date, tariffType, isTenant, selectedServices }) => {
  switch (tariffType) {
    case TARIFF_TYPES.VARIABLE:
      return <BonusesVariable serviceCount={selectedServices.length} />;

    case TARIFF_TYPES.FIXED:
      if (isTenant) {
        return (
          <BonusesFixedTenant
            date={date}
            serviceCount={selectedServices.length}
          />
        );
      }
      return (
        <BonusesFixed date={date} serviceCount={selectedServices.length} />
      );

    case TARIFF_TYPES.PREPAYMENT:
      return <BonusesPrepayment />;

    default:
      return null;
  }
};

Bonuses.propTypes = {
  date: PropTypes.string,
  tariffType: PropTypes.string,
  isTenant: PropTypes.bool,
  selectedServices: PropTypes.arrayOf(PropTypes.string),
};

export default Bonuses;
