import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';

import {
  ENERGY_USE_LOW,
  ENERGY_USE_MEDIUM,
  ENERGY_USE_HIGH,
  ENERGY_USE_CUSTOM,
} from 'redux/modules/Energy/ProductSelectionForm/constants';
import styles from './styles';

const EnergyUseSelector = ({ selected, showTitle, handleChange, classes }) => (
  <>
    <Typography className={classes.title} color="primary" variant="h3">
      {showTitle ? 'Your Energy Use' : ''}
    </Typography>
    <div className={classes.choiceContainer}>
      <CheckCard
        checkCardClasses={{
          root: classNames(classes.root, classes.right),
          buttonBase: classes.buttonBase,
        }}
        checked={selected === ENERGY_USE_LOW}
        name="energyUse"
        onChange={handleChange}
        value={ENERGY_USE_LOW}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <Typography color="primary" variant="h4">
            Low
          </Typography>
        </CardContent>
      </CheckCard>
      <CheckCard
        checkCardClasses={{
          root: classNames(classes.root, classes.right),
          buttonBase: classes.buttonBase,
        }}
        checked={selected === ENERGY_USE_MEDIUM}
        name="energyUse"
        onChange={handleChange}
        value={ENERGY_USE_MEDIUM}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <Typography color="primary" variant="h4">
            Medium
          </Typography>
        </CardContent>
      </CheckCard>
      <CheckCard
        checkCardClasses={{
          root: classNames(classes.root, classes.right),
          buttonBase: classes.buttonBase,
        }}
        checked={selected === ENERGY_USE_HIGH}
        name="energyUse"
        onChange={handleChange}
        value={ENERGY_USE_HIGH}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <Typography color="primary" variant="h4">
            High
          </Typography>
        </CardContent>
      </CheckCard>
      <CheckCard
        checkCardClasses={{
          root: classes.root,
          buttonBase: classes.buttonBase,
        }}
        checked={selected === ENERGY_USE_CUSTOM}
        name="energyUse"
        onChange={handleChange}
        value={ENERGY_USE_CUSTOM}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <Typography color="primary" variant="h4">
            Enter known usage
          </Typography>
        </CardContent>
      </CheckCard>
    </div>
  </>
);

EnergyUseSelector.propTypes = {
  selected: PropTypes.string,
  showTitle: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnergyUseSelector);
