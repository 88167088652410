import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  getSpacing,
  getBreakpoints,
  getPrimaryPaletteColor,
  getServicePaletteColor,
  getBackgroundPaletteColor,
} from 'app/lib/theme';

export default makeStyles((theme) => {
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);
  const mainColor = getPrimaryPaletteColor('main')(theme);
  const paperBackground = getBackgroundPaletteColor('paper')(theme);
  const electricity = getServicePaletteColor('electricity')(theme);
  const gas = getServicePaletteColor('gas')(theme);

  return {
    container: {
      backgroundColor: paperBackground,
      border: `2px solid ${fade(mainColor, 0.15)}`,
      borderRadius: 16,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginBottom: spacing(1),
      padding: spacing(3),
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      '&:first-child': {
        marginBottom: spacing(4),
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(1),
    },
    icon: {
      marginRight: spacing(1),
    },
    inputIcon: {
      height: 24,
      width: 24,
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    textField: {
      margin: 0,
    },
    singleTextField: {
      width: '100%',
      [breakpoints.up('sm')]: {
        width: '50%',
      },
    },
    textFieldLeft: {
      marginBottom: spacing(1),
      marginRight: spacing(0),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(0),
        marginRight: spacing(1),
      },
    },
    gasIcon: {
      color: gas,
      height: 32,
      width: 32,
    },
    electricityIcon: {
      color: electricity,
      height: 32,
      width: 32,
    },
  };
});
