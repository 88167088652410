import React from 'react';
import { Typography } from '@mui/material';

import { Alert } from 'app/components/modules/Shared/Alert';
import { useStyles } from './styles';

const InterestAlert = () => {
  const classes = useStyles();

  return (
    <Alert severity="info" className={classes.alert}>
      <Typography as="h4" variant="body" marginBottom={1}>
        Interest in the variable EV Tariff
      </Typography>
      Selecting a fixed tariff means an exit fee will apply if you decide to
      switch to the variable EV tariff before your fixed term ends. Select the
      variable tariff to revisit information about EV.
    </Alert>
  );
};

export { InterestAlert };
