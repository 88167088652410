import React from 'react';
import { Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useStyles } from './styles';
import { InterestAlert } from './InterestAlert';

const EVBanner = ({ onFindMoreClick, isVariableSelected, showSwitchAlert }) => {
  const classes = useStyles();

  if (showSwitchAlert) {
    return <InterestAlert />;
  }

  const renderDescription = () => {
    if (isVariableSelected) {
      return (
        <>
          <Typography className={classes.highlight}>
            The smart way to save on your home charging.{' '}
          </Typography>
          <Typography>
            <Link className={classes.link} onClick={onFindMoreClick}>
              Find out more about EV.
            </Link>
          </Typography>
        </>
      );
    }

    return (
      <Typography className={classes.highlight}>
        Select the variable tariff to see more details about our brand new EV
        tariff.
      </Typography>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <Typography variant="h4" className={classes.title}>
          Find out more about our new variable{' '}
          <span className={classes.green}>Electric Vehicle Tariff</span>
        </Typography>
        <div className={classes.description}>{renderDescription()}</div>
      </div>
      <div className={classes.imagePlaceholder} />
      <div className={classes.imageContainer}>
        <img className={classes.image} src={'images/evTariff/car_banner.png'} />
      </div>
    </div>
  );
};

EVBanner.propTypes = {
  onFindMoreClick: PropTypes.func.isRequired,
  isVariableSelected: PropTypes.bool.isRequired,
  showSwitchAlert: PropTypes.bool.isRequired,
};

export { EVBanner };
