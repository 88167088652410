import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';

import {
  PAYMENT_TYPE_PREPAID,
  PAYMENT_TYPE_DIRECT_DEBIT,
} from 'redux/modules/Energy/ProductSelectionForm/constants';
import styles from './styles';

export const COPY = {
  prepaidLabel: 'Select the prepaid service',
};

const PaymentTypeSelector = ({
  selected,
  showPrepaidLabel,
  handleChange,
  classes,
}) => (
  <>
    <Typography className={classes.title} color="primary" variant="h3">
      How do you pay for the energy?
    </Typography>
    <div className={classes.choiceContainer}>
      <CheckCard
        checkCardClasses={{
          root: classNames(classes.root, classes.right),
          buttonBase: classes.buttonBase,
        }}
        checked={selected === PAYMENT_TYPE_DIRECT_DEBIT}
        name="paymentType"
        onChange={handleChange}
        value={PAYMENT_TYPE_DIRECT_DEBIT}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <Typography color="primary" variant="h4">
            Direct debit
          </Typography>
        </CardContent>
      </CheckCard>
      <CheckCard
        checkCardClasses={{
          root: classes.root,
          buttonBase: classes.buttonBase,
        }}
        checked={selected === PAYMENT_TYPE_PREPAID}
        name="paymentType"
        onChange={handleChange}
        value={PAYMENT_TYPE_PREPAID}
        variant="radio"
        data-test-id="EnergyPaymentPrepay"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <Typography color="primary" variant="h4">
            Prepayment
          </Typography>
        </CardContent>
      </CheckCard>
    </div>
    {showPrepaidLabel && (
      <Typography classes={{ root: classes.prepaid }}>
        {COPY.prepaidLabel}
      </Typography>
    )}
  </>
);

PaymentTypeSelector.propTypes = {
  selected: PropTypes.string,
  showPrepaidLabel: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaymentTypeSelector);
