import React from 'react';
import classNames from 'classnames';
import {
  GAS_USE_LOW_VALUE,
  ENERGY_USE_LOW,
  ENERGY_USE_LOW_VALUE,
  ENERGY_USE_LOW_DAY_VALUE,
  ENERGY_USE_LOW_NIGHT_VALUE,
  GAS_USE_MEDIUM_VALUE,
  ENERGY_USE_MEDIUM,
  ENERGY_USE_MEDIUM_VALUE,
  ENERGY_USE_MEDIUM_DAY_VALUE,
  ENERGY_USE_MEDIUM_NIGHT_VALUE,
  GAS_USE_HIGH_VALUE,
  ENERGY_USE_HIGH,
  ENERGY_USE_HIGH_DAY_VALUE,
  ENERGY_USE_HIGH_NIGHT_VALUE,
  ENERGY_USE_HIGH_VALUE,
  ENERGY_USE_EAC,
} from 'redux/modules/Energy/ProductSelectionForm/constants';
import {
  format,
  formatEconomy7,
} from 'redux/modules/Energy/ProductSelectionForm/helpers';

const kWhYearly = 'kWh of electricity per year';

export default (classes = {}) => ({
  [ENERGY_USE_LOW]: {
    title: `Typically, ${ENERGY_USE_LOW} consumption homes are:`,
    beds: (
      <>
        <img
          className={classes.icon}
          src="images/energySelection/Bed.svg"
          alt="bed icon"
        />
        Smaller (
        <span className={classNames(classes.bold, classes.marginRight)}>1</span>
        bedroom)
      </>
    ),
    occupants: (
      <>
        <img
          className={classes.icon}
          src="images/energySelection/Sun.svg"
          alt="sun icon"
        />
        Regularly empty on weekdays
      </>
    ),
    details: (
      <>
        <img
          className={classes.homeIcon}
          src="images/energySelection/Home.svg"
          alt="home icon"
        />
        Newer (1970+)
      </>
    ),
    electricityConsumption: `${format(ENERGY_USE_LOW_VALUE)} ${kWhYearly}`,
    electricityEconomy7Consumption: formatEconomy7(
      ENERGY_USE_LOW_DAY_VALUE,
      ENERGY_USE_LOW_NIGHT_VALUE
    ),
    gasConsumption: format(GAS_USE_LOW_VALUE),
  },
  [ENERGY_USE_MEDIUM]: {
    title: `Typically, ${ENERGY_USE_MEDIUM} consumption homes:`,
    beds: (
      <>
        <img
          className={classes.icon}
          src="images/energySelection/Bed.svg"
          alt="bed icon"
        />
        Have
        <span className={classNames(classes.bold, classes.margin)}>2-3</span>
        bedrooms
      </>
    ),
    occupants: (
      <>
        <img
          className={classes.icon}
          src="images/energySelection/Family.svg"
          alt="family icon"
        />
        Have
        <span className={classNames(classes.bold, classes.margin)}>2-4</span>
        occupants
      </>
    ),
    details: (
      <>
        <img
          className={classes.icon}
          src="images/energySelection/Sun.svg"
          alt="sun icon"
        />
        May have someone home during the day
      </>
    ),
    electricityConsumption: `${format(ENERGY_USE_MEDIUM_VALUE)} ${kWhYearly}`,
    electricityEconomy7Consumption: formatEconomy7(
      ENERGY_USE_MEDIUM_DAY_VALUE,
      ENERGY_USE_MEDIUM_NIGHT_VALUE
    ),
    gasConsumption: format(GAS_USE_MEDIUM_VALUE),
  },
  [ENERGY_USE_HIGH]: {
    title: `Typically, ${ENERGY_USE_HIGH} consumption homes:`,
    beds: (
      <>
        <img
          className={classes.icon}
          src="images/energySelection/Bed.svg"
          alt="bed icon"
        />
        Have
        <span className={classNames(classes.bold, classes.margin)}> 4+ </span>
        bedrooms
      </>
    ),
    occupants: (
      <>
        <img
          className={classes.icon}
          src="images/energySelection/Family.svg"
          alt="family icon"
        />
        Have
        <span className={classNames(classes.bold, classes.margin)}>4+</span>
        occupants
      </>
    ),
    details: (
      <>
        <img
          className={classes.icon}
          src="images/energySelection/Sun.svg"
          alt="sun icon"
        />
        May have someone home during the day
      </>
    ),
    ev: (
      <>
        <img
          className={classes.icon}
          src="images/energySelection/EVChargingPoint.svg"
          alt="eletric vehicle charging point icon"
        />
        May charge an electric or hybrid vehicle
      </>
    ),
    electricityConsumption: `${format(ENERGY_USE_HIGH_VALUE)} ${kWhYearly}`,
    electricityEconomy7Consumption: formatEconomy7(
      ENERGY_USE_HIGH_DAY_VALUE,
      ENERGY_USE_HIGH_NIGHT_VALUE
    ),
    gasConsumption: format(GAS_USE_HIGH_VALUE),
  },
  [ENERGY_USE_EAC]: {},
});
