import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { features, variations } from 'app/lib/analytics/Optimizely/features';

import {
  PAYMENT_PLAN_BUDGET,
  PAYMENT_PLAN_ARREARS,
} from 'redux/modules/Energy/ProductSelectionForm/constants';
import styles from './styles';

const PaymentsSelector = ({ selected, handleChange, classes }) => {
  const { variant } = useFeature(features.HIDE_ENERGY_PAYMENT);

  if (variant === variations[features.HIDE_ENERGY_PAYMENT].ON) return null;

  return (
    <>
      <Typography className={classes.title} color="primary" variant="h3">
        Your Payment Options
      </Typography>
      <div className={classes.choiceContainer}>
        <CheckCard
          checkCardClasses={{
            root: classNames(classes.root, classes.right),
            buttonBase: classes.topLeft,
          }}
          checked={selected === PAYMENT_PLAN_BUDGET}
          name="payment"
          onChange={handleChange}
          value="budget"
          variant="radio"
          data-test-id="EnergySelectionPaymentBudget"
        >
          <CardContent classes={{ root: classes.contentPaddingCart }}>
            <Typography
              classes={{ root: classes.margin }}
              color="primary"
              variant="h4"
            >
              Budget Plan
            </Typography>
            <Typography variant="body1">
              You will pay 12 equal monthly installments
            </Typography>
          </CardContent>
        </CheckCard>
        <CheckCard
          checkCardClasses={{
            root: classes.root,
            buttonBase: classes.topLeft,
          }}
          checked={selected === PAYMENT_PLAN_ARREARS}
          name="payment"
          onChange={handleChange}
          value="arrears"
          variant="radio"
          data-test-id="EnergySelectionPaymentArrears"
        >
          <CardContent classes={{ root: classes.contentPaddingCart }}>
            <Typography
              classes={{ root: classes.margin }}
              color="primary"
              variant="h4"
            >
              Monthly in arrears
            </Typography>
            <Typography variant="body1">
              You will pay less during summer months but more during the winter
              months to reflect your usage
            </Typography>
          </CardContent>
        </CheckCard>
      </div>
    </>
  );
};

PaymentsSelector.propTypes = {
  selected: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaymentsSelector);
