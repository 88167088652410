import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { withStyles } from '@material-ui/core';

import SectionCentered from 'modules/layout/SectionCentered';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import {
  METER_STANDARD,
  PREPAID_GAS,
  PREPAID_ELECTRICITY,
  PREPAID_ELECTRICITY_AND_GAS,
  ENERGY_USE_MEDIUM,
  ENERGY_USE_CUSTOM,
  ENERGY_USE_EAC,
} from 'redux/modules/Energy/ProductSelectionForm/constants';
import {
  hasGas,
  isGas,
  hasElectricity,
  isElectricity,
  isElectricityAndGas,
  isEconomy7,
  isPrepaid,
  parseFields,
  shouldShowPayment,
  isFormComplete,
  canShowEac,
} from 'redux/modules/Energy/ProductSelectionForm/helpers';
import CollectionForm from 'redux/modules/HomeAudit/CollectionForm/container';
import { SERVICES } from 'redux/modules/HomeAudit/constants';

import EnergySelector from './Selectors/Energy';
import MeterSelector from './Selectors/Meter';
import PaymentTypeSelector from './Selectors/PaymentType';
import PrepaidSelector from './Selectors/Prepaid';
import EnergyUseSelector from './Selectors/EnergyUse';
import PaymentSelector from './Selectors/Payment';
import EstimatedAnnualConsumption from './EstimatedAnnualConsumption';
import UsageTable from './UsageTable';
import CustomUsageTable from './CustomUsageTable';
import styles from './styles';

class ProductSelectionForm extends React.Component {
  constructor(props) {
    super(props);
    if (isFormComplete(props.fields)) this.validate();
    this.validate = debounce(this.validate, 1000);
  }

  validate = () => {
    const { fields, validateForm } = this.props;
    validateForm(fields);
  };

  updatePrepaid = (energyType) => {
    if (isElectricityAndGas(energyType))
      this.updateField('prepaid', PREPAID_ELECTRICITY_AND_GAS);
    if (isElectricity(energyType))
      this.updateField('prepaid', PREPAID_ELECTRICITY);
    if (isGas(energyType)) this.updateField('prepaid', PREPAID_GAS);
  };

  updateField = (key, value) => {
    const { updateField } = this.props;
    updateField(key, value);
    this.validate();
  };

  updateEnergyTypeField = (energyType) => {
    const {
      fields: { paymentType, meter, energyUse },
      isEACEnabled,
      eacValues,
    } = this.props;
    this.updateField('energy', energyType);
    if (isGas(energyType) && isEconomy7(meter)) {
      this.updateMeterField(METER_STANDARD);
    }
    if (isPrepaid(paymentType)) this.updatePrepaid(energyType);
    if (
      !canShowEac(isEACEnabled, energyType, eacValues) &&
      energyUse === ENERGY_USE_EAC
    )
      this.updateField('energyUse', ENERGY_USE_MEDIUM);
  };

  updateMeterField = (meterType) => {
    const {
      fields: { energy, paymentType },
    } = this.props;
    this.updateField('meter', meterType);
    if (isPrepaid(paymentType)) this.updatePrepaid(energy);
    else this.updateField('prepaid', null);
  };

  updatePaymentType = (paymentType) => {
    const {
      fields: { energy },
    } = this.props;
    this.updateField('paymentType', paymentType);
    this.updatePrepaid(energy);
  };

  handleMeterRequest = () => {
    const {
      fields,
      eacValues,
      postApplicationPropertyMeterRequest,
    } = this.props;
    postApplicationPropertyMeterRequest(parseFields(fields, eacValues));
  };

  render() {
    const {
      fields: {
        energy,
        energyUse,
        meter,
        prepaid,
        paymentType,
        paymentPlan,
        electricityDayConsumption,
        electricityNightConsumption,
        electricityConsumption,
        gasConsumption,
      },
      fieldErrors,
      isValidForm,
      isRequestInProgress,
      isEACEnabled,
      eacValues,
      classes,
    } = this.props;

    const showPaymentSelector = shouldShowPayment(energy, paymentType, prepaid);
    const showPrepaid = isPrepaid(paymentType) && isElectricityAndGas(energy);
    const showCustomEnergyUsage = energyUse === ENERGY_USE_CUSTOM;
    const showEac = canShowEac(isEACEnabled, energy, eacValues);
    const showEnergySelection = energyUse !== ENERGY_USE_EAC;

    const getCurrentService = () => {
      const gas = hasGas(energy);
      const electricity = hasElectricity(energy);

      if (gas && electricity) {
        return SERVICES.GAS_ELEC;
      }

      if (gas) {
        return SERVICES.GAS;
      }

      if (electricity) {
        return SERVICES.ELECTRICITY;
      }
    };

    return (
      <>
        <SectionCentered classes={{ root: classes.root }} newLayout>
          <EnergySelector
            selected={energy}
            handleChange={(e) => this.updateEnergyTypeField(e.target.value)}
          />
          <CollectionForm
            service={getCurrentService()}
            tooltip="If you don't pay a fixed amount every month, just enter what you think you pay on average"
          />
          <MeterSelector
            selected={meter}
            isGas={isGas(energy)}
            handleChange={(e) => this.updateMeterField(e.target.value)}
          />
          <PaymentTypeSelector
            selected={paymentType}
            showPrepaidLabel={showPrepaid}
            handleChange={(e) => this.updatePaymentType(e.target.value)}
          />
          {showPrepaid && (
            <PrepaidSelector
              selected={prepaid}
              enableElectricityAndGas={isElectricityAndGas(energy)}
              enableElectricity={hasElectricity(energy)}
              enableGas={hasGas(energy)}
              handleChange={(e) => this.updateField('prepaid', e.target.value)}
            />
          )}
          {showEac && (
            <EstimatedAnnualConsumption
              {...eacValues}
              showGas={hasGas(energy)}
              showElectricity={hasElectricity(energy)}
              showDayNightUsage={isEconomy7(meter)}
              selected={energyUse}
              handleChange={(e) =>
                this.updateField('energyUse', e.target.value)
              }
            />
          )}
          {showEnergySelection && (
            <>
              <EnergyUseSelector
                showTitle={!showEac}
                selected={energyUse}
                handleChange={(e) =>
                  this.updateField('energyUse', e.target.value)
                }
              />
              {showCustomEnergyUsage ? (
                <CustomUsageTable
                  showElectricity={hasElectricity(energy)}
                  showGas={hasGas(energy)}
                  showDayNightEnergy={isEconomy7(meter)}
                  electricityDayConsumption={electricityDayConsumption}
                  electricityNightConsumption={electricityNightConsumption}
                  electricityConsumption={electricityConsumption}
                  gasConsumption={gasConsumption}
                  fieldErrors={fieldErrors}
                  handleChange={this.updateField}
                />
              ) : (
                <UsageTable
                  isEconomy7={isEconomy7(meter)}
                  showElectricity={hasElectricity(energy)}
                  showGas={hasGas(energy)}
                  energyUse={energyUse}
                  handleEditClick={() =>
                    this.updateField('energyUse', ENERGY_USE_CUSTOM)
                  }
                />
              )}
            </>
          )}
          {showPaymentSelector && (
            <PaymentSelector
              selected={paymentPlan}
              handleChange={(e) =>
                this.updateField('paymentPlan', e.target.value)
              }
            />
          )}
        </SectionCentered>
        <NavigationPane
          helpCtaEnabled
          back
          next
          autoFocus="next"
          nextLabel="Get quote"
          nextHandler={() => {
            this.handleMeterRequest();
          }}
          nextDisabled={!isValidForm || isRequestInProgress}
        />
      </>
    );
  }
}

ProductSelectionForm.propTypes = {
  eacValues: PropTypes.shape({
    electricity: PropTypes.object,
    gas: PropTypes.object,
  }),
  fields: PropTypes.object,
  fieldErrors: PropTypes.object,
  isValidForm: PropTypes.bool,
  isRequestInProgress: PropTypes.bool,
  isEACEnabled: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  postApplicationPropertyMeterRequest: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProductSelectionForm);
