import gstyles from 'app/constants/styles.js';
import colors from 'app/constants/colors.js';

export default {
  closeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tariffLabel: {
    ul: {
      margin: 0,
      padding: 0,
      color: colors.secondary.darkGrey,
      ...gstyles.FlexLayoutRowColumnMobile,
    },
    liKey: {
      listStyle: 'none',
      flex: '0 1 40%',
      textAlign: 'left',
      paddingRight: 23,
      color: colors.secondary.grey,
    },
    liFields: {
      listStyle: 'none',
      flex: '0 1 60%',
      textAlign: 'left',
    },
    faqs: {
      padding: 15,
      color: colors.primary.DarkPurple,
      title: {
        ...gstyles.fonts.h4,
        padding: 0,
        marginTop: 15,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 15,
      },
      qa: {
        padding: 15,
        backgroundColor: colors.secondary.white,
        marginBottom: 15,
        question: {
          color: colors.primary.magenta,
        },
      },
    },
  },
  container: {
    overflowY: 'scroll',
    height: 400,
    marginTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    color: colors.secondary.DarkGrey,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  moreIcon: {
    bottom: 42,
    left: '45%',
    position: 'absolute',
    width: 62,
  },
};
