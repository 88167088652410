import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  getSpacing,
  getBreakpoints,
  getPrimaryPaletteColor,
  getCommonPaletteColor,
} from 'app/lib/theme';

import { colors } from '@utilitywarehouse/colour-system';

export default (theme) => {
  const breakpoints = getBreakpoints(theme);
  const spacing = getSpacing(theme);
  const primary = getPrimaryPaletteColor('main')(theme);
  const white = getCommonPaletteColor('white')(theme);

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: spacing(2, 0),
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    left: {
      backgroundColor: primary,
      borderRadius: spacing(1, 1, 0, 0),
      padding: spacing(2),
      minWidth: 320,
      maxWidth: 337,
      [breakpoints.up('sm')]: {
        borderRadius: spacing(1, 0, 0, 1),
      },
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    right: {
      backgroundColor: white,
      border: `2px solid ${fade(primary, 0.2)}`,
      borderRadius: spacing(0, 0, 1, 1),
      borderTop: 0,
      display: 'flex',
      padding: spacing(3, 2),
      width: '100%',
      [breakpoints.up('sm')]: {
        borderTop: `2px solid ${fade(primary, 0.2)}`,
        borderLeft: 0,
        borderRadius: spacing(0, 1, 1, 0),
      },
    },
    bonuses: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    copy: {
      marginBottom: spacing(2),
    },

    messageContainer: {
      display: 'flex',
      padding: spacing(1),
      marginTop: spacing(2),
      gap: spacing(1),
      backgroundColor: theme.palette.background.default,
      borderRadius: spacing(1),
    },
    colorBoldFont: {
      fontWeight: theme.typography.fontWeightMedium,
      color: colors.cyan700,
      textDecoration: 'underline',
      '&:hover': {
        color: colors.cyan900,
        textDecorationColor: colors.cyan900,
      },
    },
    lock: {
      color: colors.red500,
      width: 48,
    },
  };
};
