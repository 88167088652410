import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Alert from '@material-ui/lab/Alert';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { ElectricityIcon, GasIcon } from '@utilitywarehouse/partner-ui-icons';

import copy from './copy';
import useStyles from './styles';

const UsageTable = ({
  isEconomy7,
  energyUse,
  showElectricity,
  showGas,
  handleEditClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <CardContent classes={{ root: classes.tableContainer }}>
        <div className={classes.tableLeft}>
          <Typography
            classes={{ root: classes.title }}
            color="primary"
            variant="h4"
          >
            {copy()[energyUse].title}
          </Typography>
          <Typography
            classes={{ root: classes.subtitle }}
            color="primary"
            variant="body1"
          >
            {copy(classes)[energyUse].beds}
          </Typography>
          <Typography
            classes={{ root: classes.subtitle }}
            color="primary"
            variant="body1"
          >
            {copy(classes)[energyUse].occupants}
          </Typography>
          <Typography
            classes={{ root: classes.subtitle }}
            color="primary"
            variant="body1"
          >
            {copy(classes)[energyUse].details}
          </Typography>
          <Typography
            classes={{ root: classes.subtitle }}
            color="primary"
            variant="body1"
          >
            {copy(classes)[energyUse].ev}
          </Typography>
        </div>
        <div className={classes.tableRight}>
          <Typography
            classes={{ root: classes.title }}
            color="primary"
            variant="h4"
          >
            Estimated consumption:
          </Typography>
          {showElectricity && (
            <Typography
              classes={{ root: classes.subtitleRight }}
              color="primary"
              variant="body1"
            >
              <span className={classes.iconWrapper}>
                <ElectricityIcon
                  className={classNames(classes.icon, classes.electricityIcon)}
                />
                {isEconomy7
                  ? copy()[energyUse].electricityEconomy7Consumption
                  : copy()[energyUse].electricityConsumption}
              </span>
              <Link
                underline="always"
                classes={{ root: classes.link }}
                onClick={handleEditClick}
              >
                Edit
              </Link>
            </Typography>
          )}
          {showGas && (
            <Typography
              classes={{ root: classes.subtitleRight }}
              color="primary"
              variant="body1"
            >
              <span className={classes.iconWrapper}>
                <GasIcon
                  className={classNames(classes.icon, classes.gasIcon)}
                />
                {copy()[energyUse].gasConsumption} kWh of gas per year
              </span>
              <Link
                underline="always"
                classes={{ root: classes.link }}
                onClick={handleEditClick}
              >
                Edit
              </Link>
            </Typography>
          )}
        </div>
      </CardContent>
      <Alert className={classes.alertContainer} severity="info">
        <Typography>
          <span className={classes.bold}>Info:</span> Figures based on national
          averages.
        </Typography>
        <Typography>
          For a more accurate quote, use your real usage from a recent bill.
        </Typography>
        <Typography>
          <span className={classes.bold}> EV drivers:</span> Because charging
          amounts vary between households, we strongly recommend entering known
          usage figures.
        </Typography>
      </Alert>
    </div>
  );
};

UsageTable.propTypes = {
  isEconomy7: PropTypes.bool,
  energyUse: PropTypes.string,
  showElectricity: PropTypes.bool.isRequired,
  showGas: PropTypes.bool.isRequired,
  handleEditClick: PropTypes.func.isRequired,
};

export default UsageTable;
