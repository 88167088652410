import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    backgroundColor: palette.common.white,
    border: `3px solid ${palette.line.primary}`,
    borderRadius: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(3),
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: spacing(2),
    marginTop: spacing(4),
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    textAlign: 'left',
    padding: spacing(5, 0, 4, 0),
  },
  subtitle: {
    fontWeight: 600,
  },
  icon: {
    height: 48,
    marginRight: spacing(3),
    width: 48,
  },
  serviceWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: spacing(4),
  },
  gas: {
    color: palette.services.gas,
  },
  electricity: {
    color: palette.services.electricity,
  },
  bold: {
    fontWeight: 'bold',
  },
  text: {
    fontSize: 20,
    fontWeight: 600,
  },
  image: {
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'initial',
      height: 248,
    },
  },
  selectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  checkCardRoot: {
    marginBottom: spacing(2),
    marginRight: 0,
    [breakpoints.up('sm')]: {
      marginBottom: 0,
      marginRight: spacing(2),
    },
  },
  checkCardBtnBase: {
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 218,
    },
  },
}));
