import React from 'react';
import PropTypes from 'prop-types';
import MultiSwitch from '@utilitywarehouse/partner-ui-mui-components/lib/MultiSwitch';
import { makeStyles } from '@material-ui/core/styles';

import { TARIFF_TYPES } from 'redux/modules/Energy/Quote/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: 213,
  },
  inactiveOptionText: {
    color: theme.palette.common.white,
  },
}));

const tariffs = [
  { value: false, label: 'Fixed' },
  { value: true, label: 'Variable' },
];

const SwitchComponent = ({ quotes, onChange }) => {
  const classes = useStyles();

  const isQuoteFixed = quotes.some((q) => q.tariff.type === TARIFF_TYPES.FIXED);
  const nextTariff = isQuoteFixed ? 'variable' : 'fixed';

  return (
    <MultiSwitch
      classes={{
        root: classes.root,
        inactiveOptionText: classes.inactiveOptionText,
      }}
      options={tariffs}
      name="energy_tariff_switch"
      value={!isQuoteFixed}
      onChange={() => onChange(nextTariff)}
    />
  );
};

SwitchComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  quotes: PropTypes.array,
};

export default SwitchComponent;
