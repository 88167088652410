import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';

const isBeforeEndDate = moment().isBefore('2022-04-01');

const getFlag = FeatureFlagsApi.getFlagSelector(
  FLAGS.ENERGY_PRICE_ANNOUNCEMENT
);

export const EnergyAlert = ({ qualifierPrefix = '*' }) => {
  const flagEnabled = useSelector(getFlag);
  if (!flagEnabled || !isBeforeEndDate) return null;

  return (
    <Box>
      <Alert variant="filled" severity="info">
        <AlertTitle>Our energy prices</AlertTitle>
        <Typography>
          The prices shown below includes the 1st of April increase to the
          government's price cap. The good news is we will continue to offer
          customers on variable energy tariffs a discount of up to £50
          {qualifierPrefix}
        </Typography>
      </Alert>
    </Box>
  );
};

export const EnergyAlertQualifier = ({ prefix = '*' }) => {
  const flagEnabled = useSelector(getFlag);
  if (!flagEnabled || !isBeforeEndDate) return null;
  return (
    <Box>
      <Typography variant="caption" color="textSecondary">
        {prefix} £50 discount applies to customers who switch their energy plus
        at least two other services (bill protector and home insurance excluded.
        Boiler & home cover also excluded for tenants). Lower discounts will be
        applied to customers taking just energy, or energy plus just one other
        service. The discount only applies to customers on a variable energy
        tariff paying by Direct Debit with a credit meter and is calculated at a
        flat rate using the typical consumption (typical domestic consumption
        values as set by Ofgem) on payment by Direct Debit, compared to the
        Government’s energy price cap level for default energy tariffs. %
        discount could be higher or lower depending on your actual consumption.
      </Typography>
    </Box>
  );
};
