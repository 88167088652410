import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  getSpacing,
  getBreakpoints,
  getPrimaryPaletteColor,
  getServicePaletteColor,
  getBackgroundPaletteColor,
} from 'app/lib/theme';

export default makeStyles((theme) => {
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);
  const mainColor = getPrimaryPaletteColor('main')(theme);
  const paperBackground = getBackgroundPaletteColor('paper')(theme);
  const electricity = getServicePaletteColor('electricity')(theme);
  const gas = getServicePaletteColor('gas')(theme);

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    tableContainer: {
      backgroundColor: paperBackground,
      border: `2px solid ${fade(mainColor, 0.15)}`,
      borderRadius: 16,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginBottom: spacing(1),
      padding: spacing(2),
      width: '100%',
      [breakpoints.up('md')]: {
        flexDirection: 'row',
        marginBottom: spacing(0),
        marginRight: spacing(2.5),
        width: '75%',
      },
    },
    tableLeft: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      [breakpoints.up('md')]: {
        marginRight: spacing(4),
      },
    },
    tableRight: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      width: '100%',
      [breakpoints.up('md')]: {
        marginLeft: spacing(1),
      },
    },
    title: {
      marginBottom: spacing(3.5),
      fontSize: '16px',
    },
    subtitle: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: spacing(3),
      minHeight: 48,
    },
    subtitleRight: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing(3),
      minHeight: 48,
    },
    iconWrapper: {
      alignItems: 'center',
      display: 'flex',
    },
    icon: {
      marginRight: spacing(1),
    },
    homeIcon: {
      marginRight: spacing(1.5),
      marginLeft: spacing(0.5),
    },
    sunIcon: {
      fontSize: '5rem',
    },
    gasIcon: {
      color: gas,
      height: 32,
      width: 32,
    },
    electricityIcon: {
      color: electricity,
      height: 32,
      width: 32,
    },
    bold: {
      fontWeight: 'bold',
    },
    margin: {
      margin: spacing(0, 0.5),
    },
    marginRight: {
      margin: spacing(0, 0.5, 0, 0),
    },
    alertContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(2),
      flexGrow: 1,
      minWidth: 164,
      width: '100%',
      [breakpoints.up('md')]: {
        width: '24%',
      },
      padding: spacing(2),
      '& > .MuiAlert-message': {
        gap: spacing(2),
        padding: 0,
      },
    },
    link: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      fontWeight: 'bold',
      [breakpoints.up('md')]: {
        marginLeft: spacing(2),
      },
    },
  };
});
