import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { getTariff } from 'redux/modules/Energy/Quote/constants';
import Switch from './Switch';

const styles = ({ spacing }) => ({
  month: {
    fontWeight: 'normal',
    marginLeft: spacing(0.5),
  },
  priceContainer: {
    alignItems: 'baseline',
    display: 'flex',
  },
  led: {
    minHeight: 24,
  },
  label: {
    fontSize: 24,
  },
  cost: {
    fontSize: 42,
  },
});

export const COPY = {
  led: 'with LED bulbs',
  month: '/ month',
};

export const getMonthlyProjection = ({ electricity, gas }) => {
  let sum = 0;
  if (electricity) sum += electricity.rawPersonalProjection.value;
  if (gas) sum += gas.rawPersonalProjection.value;
  return sum ? (sum / (12 * 100)).toFixed(0) : 'Err';
};

const getQuotesArray = ({ electricity, gas }) => {
  const quotes = [];
  if (electricity) quotes.push(electricity);
  if (gas) quotes.push(gas);
  return quotes;
};

const TariffSwitch = ({
  currentQuote,
  handleToggleTariff,
  classes,
  isPrepaymentTariff,
}) => (
  <>
    <Typography
      classes={{ root: classes.label }}
      color="textPrimary"
      variant="h3"
    >
      {getTariff(currentQuote).label}
    </Typography>
    <div className={classes.priceContainer}>
      <Typography
        classes={{ root: classes.cost }}
        color="textPrimary"
        variant="h2"
      >
        {`£${getMonthlyProjection(currentQuote)} `}
      </Typography>
      <Typography
        classes={{ root: classes.month }}
        color="textPrimary"
        variant="h4"
      >
        {COPY.month}
      </Typography>
    </div>

    {!isPrepaymentTariff && (
      <Switch
        onChange={handleToggleTariff}
        quotes={getQuotesArray(currentQuote)}
        selected={currentQuote.idLabel}
      />
    )}
  </>
);

TariffSwitch.propTypes = {
  classes: PropTypes.object,
  currentQuote: PropTypes.shape({
    electricity: PropTypes.object,
    gas: PropTypes.object,
    idLabel: PropTypes.string,
    label: PropTypes.string,
  }),
  handleToggleTariff: PropTypes.func.isRequired,
  isPrepaymentTariff: PropTypes.bool.isRequired,
};

export default withStyles(styles)(TariffSwitch);
