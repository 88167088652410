import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';

import {
  PREPAID_GAS,
  PREPAID_ELECTRICITY,
  PREPAID_ELECTRICITY_AND_GAS,
} from 'redux/modules/Energy/ProductSelectionForm/constants';

import styles from './styles';

const COPY = {
  gasAndElectricity: 'Gas and Electricity',
  electricity: 'Electricity',
  gas: 'Gas',
};

const PrepaidSelector = ({
  selected,
  enableElectricityAndGas,
  enableElectricity,
  enableGas,
  handleChange,
  classes,
}) => (
  <>
    <div className={classes.choiceContainer}>
      <CheckCard
        checkCardClasses={{
          root: classes.root,
          buttonBase: classes.buttonBase,
        }}
        checked={selected === PREPAID_ELECTRICITY_AND_GAS}
        disabled={!enableElectricityAndGas}
        name="prepaid"
        onChange={handleChange}
        value={PREPAID_ELECTRICITY_AND_GAS}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <Typography color="primary" variant="h4">
            {COPY.gasAndElectricity}
          </Typography>
        </CardContent>
      </CheckCard>
      <CheckCard
        checkCardClasses={{
          root: classNames(classes.root, classes.center),
          buttonBase: classes.buttonBase,
        }}
        checked={selected === PREPAID_ELECTRICITY}
        disabled={!enableElectricity}
        name="prepaid"
        onChange={handleChange}
        value={PREPAID_ELECTRICITY}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <Typography color="primary" variant="h4">
            {COPY.electricity}
          </Typography>
        </CardContent>
      </CheckCard>
      <CheckCard
        checkCardClasses={{
          root: classes.root,
          buttonBase: classes.buttonBase,
        }}
        checked={selected === PREPAID_GAS}
        disabled={!enableGas}
        name="prepaid"
        onChange={handleChange}
        value={PREPAID_GAS}
        variant="radio"
      >
        <CardContent classes={{ root: classes.contentCard }}>
          <Typography color="primary" variant="h4">
            {COPY.gas}
          </Typography>
        </CardContent>
      </CheckCard>
    </div>
  </>
);

PrepaidSelector.propTypes = {
  selected: PropTypes.string,
  enableElectricityAndGas: PropTypes.bool.isRequired,
  enableElectricity: PropTypes.bool.isRequired,
  enableGas: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrepaidSelector);
