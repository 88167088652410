import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core';

import { ElectricityIcon, GasIcon } from '@utilitywarehouse/partner-ui-icons';
import { formatNumber } from 'redux/utils/formatter';
import {
  PLAN_ARREARS,
  PLAN_BUDGET,
  ENERGY_ELECTRICITY,
  ENERGY_GAS,
} from 'redux/modules/Energy/constants';
import TogglePanel from 'modules/Shared/TogglePanel';
import { EVENTS } from 'app/lib/analytics/constants';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import useStyles from './styles';

const parseNum = (value) => (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
const formatCosts = ({
  ledEnergySaved,
  ledMoneySaved,
  assumedConsumptionSavingRate,
  originalElectricityConsumption,
  rawAnnualConsumption,
}) => {
  const ledEnergySavedParsed = parseNum(ledEnergySaved);
  const ledMoneySavedParsed = parseNum(ledMoneySaved);
  const assumedConsumptionSavingRateParsed =
    parseNum(assumedConsumptionSavingRate) * 100;
  const originalElectricityConsumptionParsed = parseNum(
    originalElectricityConsumption
  );
  const rawAnnualConsumptionParsed = parseNum(rawAnnualConsumption);

  return {
    ledEnergySavedParsed,
    ledMoneySavedParsed,
    assumedConsumptionSavingRateParsed,
    originalElectricityConsumptionParsed,
    rawAnnualConsumptionParsed,
  };
};

const EnergyCostRow = ({ label, value }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.costTableRow}>
      <TableCell className={classes.costTableCell}>
        <Typography>{label}</Typography>
      </TableCell>
      <TableCell className={classes.costTableCell} align="right">
        <Typography className={classes.costTableValue}>{value}</Typography>
      </TableCell>
    </TableRow>
  );
};

EnergyCostRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export const EnergyCosts = ({
  type,
  paymentPlan,
  hasDaffodil,
  quote,
  ledSavings,
  showTilDialog,
}) => {
  const classes = useStyles();
  const tracking = useTracking();
  const handleOpen = useCallback(
    (e, expanded) => {
      if (expanded) {
        tracking.track(EVENTS.ENERGY_SUMMARY_INFO_OPENED, {
          detail_name: `${type} costs`,
        });
      }
    },
    [type, tracking]
  );

  const {
    assumedConsumptionSavingRate,
    originalElectricityConsumption,
    rawAnnualConsumption,
    annualConsumption,
    personalProjection,
    tariff,
    budgetPlan,
  } = quote;

  const handleOpenTil = useCallback(() => {
    showTilDialog(tariff);
    tracking.track(EVENTS.ENERGY_SUMMARY_TIL_OPENED, {
      service: type,
    });
  }, [tracking, tariff, type, showTilDialog]);

  const {
    ledEnergySavedParsed,
    assumedConsumptionSavingRateParsed,
    originalElectricityConsumptionParsed,
    rawAnnualConsumptionParsed,
    ledMoneySavedParsed,
  } = formatCosts({
    ledEnergySaved: ledSavings.energy,
    ledMoneySaved: ledSavings.money,
    assumedConsumptionSavingRate,
    originalElectricityConsumption,
    rawAnnualConsumption,
  });

  const showBudgetCost =
    paymentPlan.toLowerCase() === PLAN_BUDGET && tariff.showDirectDebitInfo;
  const showArrearsCost =
    paymentPlan.toLowerCase() === PLAN_ARREARS && tariff.showDirectDebitInfo;

  let icon;

  if (type === ENERGY_ELECTRICITY) {
    icon = <ElectricityIcon className={classes.titleIconElectricity} />;
  }

  if (type === ENERGY_GAS) {
    icon = <GasIcon className={classes.titleIconGas} />;
  }
  return (
    <TogglePanel
      title={`Illustrative ${type} Costs`}
      icon={icon}
      onChange={handleOpen}
    >
      <>
        <TableContainer>
          <Table>
            <TableBody>
              <EnergyCostRow
                label="Annual Consumption"
                value={`${
                  hasDaffodil
                    ? formatNumber(originalElectricityConsumptionParsed)
                    : formatNumber(rawAnnualConsumptionParsed)
                } kWh`}
              />
              {hasDaffodil && (
                <>
                  <EnergyCostRow
                    label={`Assumed ${assumedConsumptionSavingRateParsed}% reduction from installation of new LED light bulbs`}
                    value={
                      <span className={classes.costTableValueGreen}>
                        &minus; {ledEnergySavedParsed} kWh
                      </span>
                    }
                  />
                  <EnergyCostRow
                    label="Revised annual consumption"
                    value={annualConsumption}
                  />
                </>
              )}

              <EnergyCostRow
                label="Personal Projection - Your estimated annual costs"
                value={personalProjection}
              />
              {showBudgetCost && (
                <EnergyCostRow
                  label="Initial Budget Plan - Monthly direct debit"
                  value={budgetPlan}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {showBudgetCost && (
          <aside className={classes.budgetCopy}>
            <Typography gutterBottom>
              All prices shown are inclusive of VAT.
            </Typography>
            <Typography gutterBottom>
              {hasDaffodil && (
                <span>
                  Price projection includes estimated £{ledMoneySavedParsed}{' '}
                  saving from our free LED light bulb replacement service.&nbsp;
                </span>
              )}
              The above Budget Plan amount will automatically be reviewed after
              3 months in line with your actual energy consumption provided you
              give us any meter readings requested.
            </Typography>
          </aside>
        )}

        {showArrearsCost && (
          <Typography gutterBottom>
            Monthly in arrears means that your monthly direct debit amount will
            equal your bill amount.
          </Typography>
        )}

        <Button
          onClick={handleOpenTil}
          variant="outlined"
          color="secondary"
          classes={{
            root: classNames(
              classes.tilDialog,
              !showBudgetCost && !showArrearsCost && classes.btnMargin
            ),
          }}
        >
          View Tariff Information Label
        </Button>
      </>
    </TogglePanel>
  );
};

EnergyCosts.defaultProps = {
  ledSavings: {},
};

EnergyCosts.propTypes = {
  type: PropTypes.string.isRequired,
  quote: PropTypes.shape({
    annualConsumption: PropTypes.string.isRequired,
    rawAnnualConsumption: PropTypes.number.isRequired,
    personalProjection: PropTypes.string.isRequired,
    budgetPlan: PropTypes.string.isRequired,
    tariff: PropTypes.shape({
      showDirectDebitInfo: PropTypes.bool.isRequired,
    }).isRequired,
    assumedConsumptionSavingRate: PropTypes.number,
    originalElectricityConsumption: PropTypes.number,
  }).isRequired,
  paymentPlan: PropTypes.string.isRequired,
  hasDaffodil: PropTypes.bool,
  showTilDialog: PropTypes.func.isRequired,
  ledSavings: PropTypes.shape({
    energy: PropTypes.number,
    money: PropTypes.string,
  }),
};

export default EnergyCosts;
