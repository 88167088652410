import { getSpacing, getServicePaletteColor } from 'app/lib/theme';

export default (theme) => {
  const electricity = getServicePaletteColor('electricity')(theme);
  const gas = getServicePaletteColor('gas')(theme);
  const spacing = getSpacing(theme);

  return {
    root: {
      paddingBottom: spacing(2),
    },
    icon: {
      height: 48,
      marginBottom: spacing(3),
      width: 48,
    },
    gas: { color: gas },
    electricity: { color: electricity },
    title: {
      textAlign: 'left',
      padding: spacing(4, 0),
    },
    contentCard: {
      padding: spacing(2),
    },
    iconContainer: {
      '& > *:last-child': {
        marginLeft: spacing(2),
      },
    },
    checkCard: {
      minWidth: 248,
    },
    choiceContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
    },
    usageTable: {
      display: 'flex',
    },
  };
};
