import React, { useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { Typography } from '@material-ui/core';
import { InfoOutlined } from '@utilitywarehouse/partner-ui-icons';
import { makeStyles } from '@material-ui/core/styles';

import TogglePanel from 'modules/Shared/TogglePanel';
import { EVENTS } from 'app/lib/analytics/constants';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';

const useStyles = makeStyles((theme) => ({
  titleIconInfo: {
    color: theme.palette.line.primary,
  },
}));

const Info = ({ showSavings }) => {
  const classes = useStyles();
  const tracking = useTracking();
  const handleOpen = useCallback(
    (e, expanded) => {
      if (expanded) {
        tracking.track(EVENTS.ENERGY_SUMMARY_INFO_OPENED, {
          detail_name: 'Important information',
        });
      }
    },
    [tracking]
  );
  return (
    <TogglePanel
      title="Important information"
      icon={<InfoOutlined className={classes.titleIconInfo} />}
      onChange={handleOpen}
    >
      <Typography gutterBottom>
        To make sure you know the key facts about your agreement, please take a
        look at the Personal Projection and Tariff Information Label we&apos;ve
        given you.
      </Typography>
      <Typography gutterBottom>
        All prices shown include VAT. If you&apos;d like to discuss different
        payment options and the tariffs for them, please don&apos;t hesitate to
        call us on <a href="tel:03337773212">0333&nbsp;777&nbsp;3&nbsp;212</a>.
        We&apos;re happy to help. And if you change your mind, you can cancel
        the agreement within 14 days, or 30 days if you take another service in
        addition to Energy.
      </Typography>
      <Typography gutterBottom>
        Please remember that any quotations given are estimates based on what
        you&apos;ve told us about your energy usage or property.
      </Typography>
      <Typography gutterBottom>
        If you have smart meters from another supplier, we'll be able to keep
        their smart functionality if your old provider installed smart meters
        like ours. And we're working hard to support all types of smart meters.
        But, in certain cases, you might temporarily lose some smart meter
        features, for example automatic meter readings. If you've got prepayment
        smart meters, they'll be turned to 'credit mode' before you join us – so
        you won't be left without any energy, and you&apos;ll get a bill for
        your energy instead of having to top the meter up. When your switch is
        complete, get in touch with us and we'll turn them back to prepayment
        meters, or fit new ones at no extra cost.
      </Typography>
      {showSavings && (
        <Typography gutterBottom>
          * We'll beat the Government's 'Energy Price Guarantee' by up to £50
          and we promise to keep them lower than the price cap for as long as
          you remain a customer. Saving are compared to Government's 'Energy
          Price Guarantee' for default energy tariffs based on payment by direct
          debit for typical household consumption of 2,900 kWh electricity and
          12,000 kWh gas. When any changes to the Government's 'Energy Price
          Guarantee' are made, we will adjust our own tariffs to ensure they
          comply with our savings for the date the new Energy Price Guarantee is
          introduced.
        </Typography>
      )}
    </TogglePanel>
  );
};

Info.propTypes = {
  showSavings: PropTypes.bool.isRequired,
};

export default Info;
